



const ClarityCall = () => {
    return (
        <div className="clarity-call">
            <div className="clarity-call-container">
                <div className="clarity-call-content">
                    <h3 className="text-h3 font-semibold text-brand-800">What to expect from Clarity Call?</h3>
                    <p className="text-h6 text-textBody">
                        In a 60-minute session, we ll:
                    </p>
            <ul className="text-h6 text-textBody">
                <li>•  Explore your current challenges and areas where you feel stuck.</li>
                <li>•  Identify your goals and what s holding you back from achieving them.</li>
                        <li>•  Create a tailored action plan to give you direction and motivation.</li>
                    </ul>
                    <p className="text-h6 font-semibold text-textHeading">
                        Whether you re looking to improve confidence, find purpose, or make a career change, this call is designed to give you the insight and momentum you need.
                    </p>
                </div>
                <img className="clarity-call-image" src={`${process.env.PUBLIC_URL}/assets/why_me_illustration.jpg`} alt="Why" />
            </div>
        </div>
    );
}

export default ClarityCall;