const Footer = () => {
    return (
        <div className="footer-section">
            <div className="footer-section-links">
                <div className="footer-section-link">
                    <img src={`${process.env.PUBLIC_URL}/assets/instagram.png`} alt="instagram" />
                    <a href="https://www.instagram.com/vidyasireesha.official" target="_blank" rel="noreferrer" className="text-brand-800 font-semibold">@vidyasireesha.official</a>
                </div>
                <div className="footer-section-link">
                    <img src={`${process.env.PUBLIC_URL}/assets/email.svg`} alt="mail" />
                    <a href="mailto:officeofvidyasireesha@gmail.com" target="_blank" rel="noreferrer" className="text-brand-800 font-semibold">officeofvidyasireesha@gmail.com</a>
                </div>
            </div>
           
        </div>
    )
}

export default Footer;