

const AboutMeSection = () => {
    return (
        <section  className="about-me" id="about-me">
            <img className="about-me-image" src={`${process.env.PUBLIC_URL}/assets/about_me.png`} alt="Vidya Sireesha" />
           
            <div className="about-me-content">
                < h3 className="about-me-title">About Me</h3>
                <h6 className=" about-me-text">
                Hi, I’m Vidya Sireesha, a certified life coach with over three years of experience helping individuals transform their lives. My passion is guiding clients to discover their strengths, set meaningful goals, and overcome barriers to personal growth. Through a blend of supportive coaching and actionable strategies, I empower people like you to create positive change and step confidently toward their vision.
                </h6>
            </div>
        </section>
    );
}

export default AboutMeSection;