import Button from './button'; // Import Button if you plan to use it
import { useEffect, useState, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import HeroSection from './HeroSection';
import AboutMeSection from './AboutMeSection';
import BookingForm from './BookingForm';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-scroll';



const Navbar = () => {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            console.log("Removing event listener");
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    // Custom scroll function to apply an offset
    // const scrollWithOffset = (el) => {
    //     const yOffset = -800; // Adjust this value to match the height of your navbar
    //     const y = el.getBoundingClientRect().top + window.scrollY; // Use window.pageYOffset for cross-browser compatibility
    //     console.log(`${y}`);
    //     window.scrollTo({ top: y, behavior: 'smooth' });
        
    // };

    return (
        <nav className="navbar">
            <Routes>

                <Route path="/home" element={<HeroSection />} />
                <Route path="/about-me" element={<AboutMeSection />} />
                <Route path="/booking-form" element={<BookingForm />} />
            </Routes>

            <div className="navbar-left">
                <img className="logo-img" src={`${process.env.PUBLIC_URL}/assets/Brand_Logo.png`} alt="Brand Logo" />
                <h1 className="brand-name">Vidya Sireesha</h1>
            </div>
            <div className="hidden md:flex navbar-right">
                <Link 
                    className="button-text" 
                    to="home" 
                    smooth={true} 
                    duration={500} 
                    offset={-80} // Adjust this value to match the height of your navbar
                >
                    Home
                </Link>
                <Link 
                    className="button-text" 
                    to="about-me" 
                    smooth={true} 
                    duration={500} 
                    offset={-80} // Adjust this value to match the height of your navbar
                >
                    About Me
                </Link>
                <Link 
                    className="button-text" 
                    to="booking-form" 
                    smooth={true} 
                    duration={500} 
                    offset={-80} // Adjust this value to match the height of your navbar
                >
                    Book Appointment
                </Link>
            </div>
            <div className="navbar-right-mobile flex md:hidden">
                <Button type="text" onClick={toggleDropdown}>
                    Menu
                </Button>
                {dropdownVisible && (
                    <div ref={dropdownRef} id="dropdown-menu" className="dropdown-modal absolute top-16 right-0 bg-white">
                        <HashLink className="button-text" onClick={toggleDropdown} smooth to="/#home">Home</HashLink>
                        <HashLink className="button-text" onClick={toggleDropdown} smooth to="/#about-me">About Me</HashLink>
                        <HashLink className="button-text" onClick={toggleDropdown} smooth to="/#booking-form">Book Appointment</HashLink>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;  