import React, { useState } from 'react';

const Button = ({ type = 'primary', children, icon, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);

    const baseStyles = 'flex flex-row gap-2 flex-grow-1 items-center justify-center px-6 py-3 rounded font-bold focus:outline-none';
    const typeStyles = {
        primary: 'bg-brand-800 text-white text-h6 hover:bg-brand-900',
        secondary: 'bg-surfaceTwo text-onSurface',
        text: 'bg-transparent text-brand-800 hover:text-brand-900 text-h6 px-2 truncate',
        icon: 'bg-transparent hover:bg-brand-100',
    };

    const hoverStyles = isHovered ? 'opacity-80' : '';
    const activeStyles = isPressed ? 'transform scale-95' : '';
    const underlineStyles = isPressed && type === 'text' ? 'underline' : '';

    return (
        <button className={`${baseStyles} ${typeStyles[type]} ${hoverStyles} ${activeStyles} ${underlineStyles}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => {
                setIsHovered(false);
                setIsPressed(false);
            }}
            onMouseDown={() => setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onBlur={() => {
                setIsPressed(false);
            }}
            onClick={onClick} // Handle click event
        >
            {icon && <span className="icon">{icon}</span>} {/* Render icon if provided */}
            {children}
        </button>
    );
};

export default Button;