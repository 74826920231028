import Button from "../components/button.js";

const WhySection = () => {
    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            const offset = -120;
            element.scrollIntoView({ behavior: 'smooth', offset: offset, block: 'start' });
        }
    };

    return (
        <div className="why-section">
            <div className="why-content">
                <div className="why-content-right">
                    <img className="why-image" src={`${process.env.PUBLIC_URL}/assets/frustrated_llustration.png`} alt="Why" />
                </div>

                <div className="why-content-left">
                    <h3 className="text-brand-800">“Life feels like it’s passing by,
                    and I’m not achieving what I want”</h3>
                    <h6 className="mt-2 text-textBody">
                    Do you feel like time is slipping by, leaving you wondering if you’re truly living the life you want?  Many people feel this way, working hard but unsure if they’re on the right path. Together, we can clarify your goals, uncover what truly matters, and create a plan to bring you closer to a fulfilling life.
                    </h6>
                    <h6 className="mt-4">
                    Don’t let another day go by without taking a step toward the change you deserve.
                    if you are a new age mom or an entrepreneur or an ambitious young woman juggling your priorities.
                    I SEE YOU. Come, lets work together. Let yourself blossom.
                    
                    Every accomplishment starts with the decision to try.
                    </h6>
                    <Button onClick={() => scrollToElement('booking-form')} type="primary" className="mt-4 max-w-lg">Schedule a Clarity Call Now</Button>
                </div>
               
            </div>
            
        </div>
    );
}

export default WhySection;