import React from 'react';

const Testimonial = ({ imageSrc, name, testimonialText }) => {
    return (
        <div className="testimonial-container">
            <img className="testimonial-image" src={imageSrc} alt="testimonial" />
            <div>
                <h4 className="text-brand-800 font-semibold">{name}</h4>
                <p className="text-textBody font-thin">{testimonialText}</p>
            </div>
        </div>
    );
}

export default Testimonial;