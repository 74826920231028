import './App.css';

import HeroSection from './components/HeroSection';
import AboutMeSection from './components/AboutMeSection';
import ClarityCallSection from './components/ClarityCallSection';
import WhySection from './components/WhySection';
import BookingForm from './components/BookingForm';
import Testimonial from './components/Testimonial';
import Footer from './components/Footer';


const testimonials = [
  {
    imageSrc: `${process.env.PUBLIC_URL}/assets/sonal.jpeg`,
    name: "Sonal",
    testimonialText: "Dear Vidya, thank you so much for all that you do with so much love & kindness in your heart, it just reflects in your words & actions.. I am so blessed to connect with you & the wisdom you share so effortlessly like a magical flow creating lifelong transformations. Thankyou for creating this world a better place for our future generations to come. Keep shining & spread the light of love & happiness. Love you .. more power to you 🥰"
  },
  {
    imageSrc: `${process.env.PUBLIC_URL}/assets/poorti.jpeg`,
    name: "Poorti",
    testimonialText: "Vidya is a very calm and composed coach and makes you feel comfortable & at ease in her sessions. This has helped me to open about my challenges easily with her. She has helped me create and build habits to focus and work on my goals more productively. Thankyou Vidya for being you & all the help. Looking forward for more sessions 😊"
  },
  {
    imageSrc: `${process.env.PUBLIC_URL}/assets/pratiksha.jpeg`,
    name: "Pratiksha",
    testimonialText: "Vidya Sireesha is one of the best mindset coaches for women, that I've come across in recent times. I have attended many of her clubhouse lives on various topics regarding how to stick to your habits, improving relationships, personal development etc. She truly wants to transform the world and has been there for me whenever I need her the most. I recommend all of you reading this, to consider taking your life transforming sessions from her. It'll be totally worth it. I Promise!!!"
  }
]


function App() {
  return (  

    <div>
      <div className="system-container">
        <div className="content-container items-center justify-center flex flex-col ">


          <HeroSection id="home" />
          <WhySection />
          <AboutMeSection id="about-me"/>
          <ClarityCallSection />
          <div className="testimonial-section">
            <h2 className="text-center text-brand-800 font-semibold">Testimonials</h2>
            <div className="testimonial-section-body">
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index} {...testimonial} />
              ))}
            </div>
          </div>
          <BookingForm id="booking-form" />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
