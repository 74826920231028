import Button from './button';
import { Element } from 'react-scroll';
const HeroSection = () => {
    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            const offset = -120;
            element.scrollIntoView({ behavior: 'smooth', offset: offset, block: 'start' });
        }
    };

    return (
        <Element name="home" id="home" className="hero-section">
            <div className="hero-left">
                <h2 className="text-brand-800">
                    Unlock Your Potential: <br/>Dear fabulous woman, Start Your Journey to Healing and Confidence
                </h2>
                <h6 className="mt-2 text-textBody">
                Experience a one-on-one clarity call to uncover your goals, identify obstacles, and map out the first steps toward the life you desire.
                </h6>
                <Button onClick={() => scrollToElement('booking-form')} type="primary" className="mt-4 w-fit">Schedule a Clarity Call Now</Button>
            </div>
            <div className="hero-right">
                <img className="hero-image" src={`${process.env.PUBLIC_URL}/assets/Hero_Image.png`} alt="Vidya Sireesha" />
            </div>
        </Element>
    );
}

export default HeroSection;