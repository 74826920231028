import Button from "../components/button.js";
import emailjs from '@emailjs/browser';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BookingForm = () => {
    const [callType, setCallType] = React.useState("Zoom Meeting");
    const formRef = React.useRef();
    const [sendStatus, setSendStatus] = React.useState("idle");
    
   

    const handleSendStatus = (status) => {
        setSendStatus(status);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleSendStatus('loading');

        const formData = new FormData(event.target);

        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            date: formData.get('date'),
            callType: formData.get('callType'),
            message: formData.get('message'),
            phone: formData.get('phone'),
        };

        // console.log(data);

        var templateParams = {
            name: data.name,
            from_email: data.email,
            date: data.date,
            calltype: data.callType,
            message: data.message,
            phone: data.phone,
          };

        if (sendStatus === 'loading') {
            return;
        }

        handleSendStatus('loading');

        // Send email to self
        emailjs.sendForm(process.env.REACT_APP_SELF_EMAILJS_SERVICE_ID, process.env.REACT_APP_SELF_EMAILJS_TEMPLATE_ID, event.target, process.env.REACT_APP_SELF_EMAILJS_PUBLIC_KEY)
        .then((result) => {
            console.log('Email sent to self', result.text);
            handleSendStatus('success');
        }, (error) => {
            console.log('Error sending email to self', error.text);
            handleSendStatus('error');
            toast.error("Failed to send your booking request. Please try again.");
        });

        // Send email to client
        
       

        emailjs.sendForm(process.env.REACT_APP_CLIENT_EMAILJS_SERVICE_ID, process.env.REACT_APP_CLIENT_EMAILJS_TEMPLATE_ID, event.target, process.env.REACT_APP_CLIENT_EMAILJS_PUBLIC_KEY, templateParams)
        .then((result) => {
            console.log('Email sent to client', result.text);
        }, (error) => {
            console.log('Error sending email to client', error.text);
            toast.error("Failed to send your booking request. Please try again.");
        });

       
    };

    const handleCallTypeChange = (event) => {
        setCallType(event.target.value);
    };

    return (
        <section className="booking-form" id="booking-form">
            <ToastContainer />
            <form ref={formRef} className="booking-form-container" netlify onSubmit={handleSubmit} >
                <input type="hidden" name="form-name" value="booking-form" />
                <h2 className="text-brand-800 text-center">Book a Clarity Call</h2>
                <h6 className="text-textBody text-center">
                Take the first step toward a life of purpose and confidence. Fill out the form below to schedule your free clarity call and begin your journey.
                </h6>
                <input className="booking-form-input" name="name" required type="text" placeholder="Name" />
                <input className="booking-form-input" name="email" required type="email" placeholder="Email" />
                <div className="booking-form-radio-container">
                    <label className="text-h6  text-brand-800 text-nowrap">Select Type of Call:</label>
                    <div className="options-container">
                        <label className="booking-form-radio-option">
                            <input  className="size-4" id="zoom-meeting" defaultChecked type="radio" name="callType" value="Zoom Meeting" required onChange={handleCallTypeChange} />
                            <h6 className="booking-form-radio-label text-nowrap">Zoom Meeting</h6>
                        </label>
                        <label className="booking-form-radio-option">
                            <input className="size-4" id="over-mobile" type="radio" name="callType" value="Over Mobile" required onChange={handleCallTypeChange} />
                            <h6 className="booking-form-radio-label text-nowrap">Over Mobile</h6>
                        </label>
                    </div>  
                </div>
                {callType === "Over Mobile" && (
                    <input className="booking-form-input" name="phone" type="tel" placeholder="Phone" />
                )}
                <div className="booking-form-date-container">
                    <label className="text-h6 text-brand-800">Select Preferred Call Date:</label>
                    <input className="booking-form-input" name="date" required type="date" placeholder="Select Preferred Call Date" />
                </div>
                <textarea className="booking-form-textarea" name="message"  placeholder="Write here..." rows="4"></textarea>
                <label className="text-caption text-brand-700 mb-2">Share your goals, challenges, or anything on your mind—your input is confidential and helps us make the most of our session.</label>
                
                <Button type="primary" value="submit"  className="flex flex-grow w-full">Schedule A Call Now</Button>

                </form>
        </section>
    );
}

export default BookingForm;